<template>
  <v-container
      id="registry"
      fluid
      align="center"
      tag="section"
  >

    <v-row no-gutters>
      <v-col cols="12" md="12">
        <base-material-card
            color="success"
            icon="mdi-cog"
            inline
            title="Settings"
        >
          <v-tabs right>
            <v-tab v-show="canAccess({method: 'get', route: '/environments/:env'})">
              <v-icon class="mr-2">mdi-cog</v-icon>
              Registry
              <help-circle :context="{bottom: true, link: 'registry', text: 'Environment Registry'}"></help-circle>
            </v-tab>
            <v-tab v-show="canAccess({method: 'get', route: '/environments/:env/:type/custom-entries'})">
              <v-icon class="mr-2">mdi-cogs</v-icon>
              Custom
              <help-circle :context="{bottom: true, link: 'custom', text: 'Custom Registries'}"></help-circle>
            </v-tab>
            <v-tab v-show="canAccess({method: 'get', route: '/environments/:env/:type/custom-entries'})">
              <v-icon class="mr-2">mdi-account-network</v-icon>
              Social
              <help-circle :context="{bottom: true, link: 'socialPlatforms', text: 'Custom Social Registries'}"></help-circle>
            </v-tab>
            <v-tab v-show="canAccess({method: 'get', route: '/environments/:env/:type/custom-entries'})">
              <v-icon class="mr-2">mdi-robot</v-icon>
              ARW
              <help-circle :context="{bottom: true, link: 'arw', text: 'Automated Registration Workflows'}"></help-circle>
            </v-tab>

            <v-tab-item><registry :environments="environments" :env-selected="envSelected"></registry></v-tab-item>
            <v-tab-item>
              <custom-entries :environments="environments" :env-selected="envSelected" :custom-entry-type="'default'"></custom-entries>
            </v-tab-item>
            <v-tab-item>
              <custom-entries :environments="environments" :env-selected="envSelected" :custom-entry-type="'social'"></custom-entries>
            </v-tab-item>
            <v-tab-item>
              <custom-entries :environments="environments" :env-selected="envSelected" :custom-entry-type="'arw'"></custom-entries>
            </v-tab-item>
          </v-tabs>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>

import Registry      from '@/views/internal/pages/settings/Registry';
import CustomEntries from "@/views/internal/pages/settings/Custom";
import globalMixins  from "@/mixins/globalMixins";
import HelpCircle    from "@/views/internal/components/custom/helpCircle";

export default {
  name: 'settings',

  mixins: [globalMixins],

  components: {
    HelpCircle,
    CustomEntries,
    Registry
  },
  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    }
  }
};
</script>