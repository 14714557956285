<template>
  <v-container
      id="registry"
      fluid
      align="center"
      tag="section"
  >

    <v-row no-gutters>
      <v-col cols="12" md="12">
        <template v-if="!currentEnv">
          <v-progress-linear
              indeterminate
              color="primary"
          ></v-progress-linear>
          <v-skeleton-loader loading class="mx-auto" type="card"></v-skeleton-loader>
        </template>
        <v-tabs vertical v-else>

          <v-tab
              v-for="(tab, i) in tabs"
              :key="i"
              class="pl-4 mb-1 text-left justify-start"
          >
            <v-tooltip right>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                >mdi-{{ tab.icon }}
                </v-icon>
              </template>

              <span>{{ tab.label }}</span>
            </v-tooltip>
          </v-tab>

          <v-tab-item>
            <v-btn color="primary" class="mx-auto" small elevation="3" @click="initialize">
              <v-icon small color="white">mdi-refresh</v-icon>
            </v-btn>

            <v-card
                elevation="2"
                class="mb-0 mt-3"
            >
              <v-card-title class="mb-3">General Information</v-card-title>
              <v-card-subtitle class="font-italic">Configure the Registry settings of this environment.
              </v-card-subtitle>
              <v-divider class="mx-3 mt-3"></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Name"
                        v-model="currentEnv.code"
                        readonly
                        hint="An environment code is Unique. It is the main reference based on which all configuration and settings are loaded."
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        hint="Optional description only used for management purposes"
                        label="Description"
                        v-model="currentEnv.description"
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-select
                        label="Caching TTL *"
                        v-model="currentEnv._TTL"
                        required
                        item-text="text"
                        item-value="value"
                        :items="msCacheOptions"
                        hint="The gateway caches the environment configuration at runtime and that enables it to operate at optimal speed."
                        persistent-hint
                    ></v-select>

                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>

            <v-card
                elevation="2"
                class="my-0"
            >
              <v-card-title class="mb-3">Logger Configuration</v-card-title>
              <v-card-subtitle class="font-italic">Configure the Logger settings of the M360API Gateway.
              </v-card-subtitle>
              <v-divider class="mx-3 mt-3"></v-divider>
              <v-card-text>
                <!--Ref: https://github.com/josdejong/jsoneditor/blob/master/docs/api.md#configuration-options?blank-->
                <v-jsoneditor
                    class="py-2"
                    v-model="currentEnv.logger" :plus="true" :options="{mode: 'code'}" height="400px"
                />
                <v-card-subtitle class="font-italic">Modify the settings of the M360API Logger, default: <a
                    target="_blank" href="https://www.npmjs.com/package/bunyan">Bunyan</a></v-card-subtitle>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item>
            <v-card
                elevation="2"
                class="my-0"
            >
              <v-card-title class="mb-3">Analytics Information</v-card-title>
              <v-card-subtitle class="font-italic">Configure the Metrics & Analytics settings of the M360API Gateway.
              </v-card-subtitle>
              <v-divider class="mx-3 mt-3"></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="URI"
                        v-model="currentEnv.stats.uri"
                        readonly
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-text-field
                        label="Prefix"
                        v-model="currentEnv.stats.prefix"
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12">
                    <v-switch label="Secure" inset v-model="currentEnv.stats.secure"></v-switch>
                  </v-col>
                  <v-col cols="12">
                    <v-switch label="Enable SAAS Gateway Bypass" inset v-model="currentEnv.stats.saasGtw"></v-switch>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>

          <v-tab-item v-if="fieldAccess.security">

            <v-card v-if="fieldAccess.security" elevation="2" class="my-0">
              <v-card-title class="mb-3">Tenant Security</v-card-title>
              <v-card-subtitle class="font-italic">Modify the Multitenancy encryption phrase of the M360API
                Gateway.
              </v-card-subtitle>
              <v-divider class="mt-3 mx-3"></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        label="Password"
                        v-model="currentEnv.security.key.password"
                        hint="Set the Multitenancy encryption phrase of the M360API Gateway."
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="fieldAccess.security" elevation="2">
              <v-card-title class="mb-3">OAuth Security</v-card-title>
              <v-card-subtitle class="font-italic">Modify the OAuth settings of the M360API Gateway.</v-card-subtitle>
              <v-divider class="mt-3 mx-3"></v-divider>
              <v-card-text>
                <v-row>

                  <v-col cols="12">
                    <v-text-field
                        label="Secret"
                        v-model="currentEnv.security.oauth.secret"
                        hint="Set the encryption phrase of the Oauth security."
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Encryption Strength"
                        v-model="currentEnv.security.oauth.hashSize"
                        hint=""
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">

                    <v-select
                        label="Access Token Lifetime (seconds)"
                        v-model="currentEnv.security.oauth.accessTokenLifetime"
                        :rules="[() => !!currentEnv.security.oauth.accessTokenLifetime || 'This field is required!']"
                        required
                        item-text="text"
                        item-value="value"
                        :items="secondsCacheOptions"
                        hint="Set the lifetime of the Oauth access token that the gateway uses."
                        persistent-hint
                    ></v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                        label="Refresh Token Lifetime (seconds)"
                        v-model="currentEnv.security.oauth.refreshTokenLifetime"
                        :rules="[() => !!currentEnv.security.oauth.refreshTokenLifetime || 'This field is required!']"
                        required
                        item-text="text"
                        item-value="value"
                        :items="secondsCacheOptions"
                        hint="Set the lifetime of the Oauth refresh token that the gateway uses."
                        persistent-hint
                    ></v-select>

                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Grants Strategy"
                        v-model="currentEnv.security.oauth.grants"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-switch label="Debug" inset v-model="currentEnv.security.oauth.debug"></v-switch>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="fieldAccess.security" elevation="2">
              <v-card-title class="mb-3">CORS</v-card-title>
              <v-card-subtitle class="font-italic">Modify the CORS settings of the M360API Gateway.</v-card-subtitle>
              <v-divider class="mt-3 mx-3"></v-divider>

              <v-card-text>
                <v-row>
                  <v-col cols="12">
                    <v-switch label="Enable" inset
                              persistent-hint
                              hint="Configure the CORS security of the gateway."
                              v-model="currentEnv.security.cors.enabled"></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-switch label="Allow Credentials" inset
                              persistent-hint
                              hint="Set whether to allow or deny credentials support in CORS."
                              v-model="currentEnv.security.cors.credentials"></v-switch>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Allow Origin"
                        v-model="currentEnv.security.cors.origin"
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                        v-model="allowed_corsMethods"
                        :items="['GET','HEAD','PUT','PATCH','POST','DELETE']"
                        label="Methods"
                        multiple
                        chips
                        clearable
                        deletable-chips
                        hint="Select which Methods are allowed in the CORS Configuration."
                        persistent-hint
                    >
                      <template v-slot:prepend-item>
                        <v-list-item
                            ripple
                            @click="selectAllNoneCorsMethods"
                        >
                          <v-list-item-action>
                            <v-icon
                                :color="cors_methods.length === allowed_corsMethods.length ? 'indigo darken-4' : ''">
                              {{
                                cors_icon
                              }}
                            </v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title>Select All</v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                        <v-divider class="mt-2"></v-divider>
                      </template>

                      <template v-slot:selection="data">
                        <!-- HTML that describe how select should render selected items -->
                        <v-chip close color="primary">
                          <span>{{ data.item }}</span>
                        </v-chip>
                      </template>

                      <template v-slot:item="data">
                        <!-- HTML that describe how select should render items when the select is open -->
                        <strong class="mr-3">{{ data.item }}</strong>
                      </template>

                      <template v-slot:append-item>
                        <v-divider class="mb-2"></v-divider>
                        <v-list-item disabled>
                          <v-list-item-content>
                            <v-list-item-title>
                              CORS Configuration can include multiple methods.
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>
                    </v-select>
                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        label="Allowed Headers"
                        v-model="currentEnv.security.cors.headers"
                        hint="Add here all the possible key values that are needed in your headers"
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">
                    <v-select
                        label="Max Age *"
                        v-model="currentEnv.security.cors.maxAge"
                        :rules="[() => !!currentEnv.security.cors.maxAge || 'This field is required!']"
                        required
                        item-text="text"
                        item-value="value"
                        :items="secondsCacheOptions"
                        hint="Set the Max Age of your CORS configuration."
                        persistent-hint
                    ></v-select>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
            <v-card v-if="fieldAccess.security" elevation="2">
              <v-card-title class="mb-3">MFA Security</v-card-title>
              <v-card-subtitle class="font-italic">Modify the MFA security settings.</v-card-subtitle>
              <v-divider class="mt-3 mx-3"></v-divider>
              <v-card-text>
                <v-row>
                  <v-col cols="12">

                    <v-switch label="Enable MFA" inset persistent-hint
                              hint="Specify whether or not to enable Multi Factor Authentication."
                              v-model="currentEnv.security.mfa.enabled"></v-switch>
                  </v-col>
                </v-row>
                <v-row v-if="currentEnv.security.mfa.enabled">
                  <v-col cols="12">
                    <v-text-field label="TTL"
                                  :rules="rules.TTL"
                                  type="number" hint="Specify the OTP TTL in minutes."
                                  v-model="currentEnv.security.mfa.config.TTL"
                    ></v-text-field>

                  </v-col>

                  <v-col cols="12">
                    <v-text-field
                        hint="Specify the URL of the page that the user should be redirected to, to enter the OTP."
                        label="OTP page URL"
                        :rules="rules.URL"
                        v-model="currentEnv.security.mfa.config.redirectURL"
                        persistent-hint
                    >
                    </v-text-field>
                  </v-col>

                  <v-col cols="12">

                    <v-select
                        label="Notification Server"
                        v-model="currentEnv.security.mfa.config.default"
                        required
                        item-text="text"
                        item-value="value"
                        :items="mfaConf"
                        hint="Select the notification server that should be used to send the OTP code to the user."
                        persistent-hint
                    ></v-select>
                  </v-col>

                  <v-col cols="12" v-if="this.currentEnv.security.mfa.config.default === false">
                    <v-expansion-panels multiple :value="[0,1]" focusable>
                      <v-expansion-panel>
                        <v-expansion-panel-header>Custom Notification Server</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-switch inset v-model="remote" label="Invoke 3rd Party System"></v-switch>
                          <template v-if="currentEnv.security.mfa.config.webhook">
                            <v-text-field
                                v-if="remote"
                                label="URL *"
                                :rules="[() => !!currentEnv.security.mfa.config.webhook.uri || 'This field is required!']"
                                v-model="currentEnv.security.mfa.config.webhook.uri"
                                counter
                                required
                            />
                            <v-row v-else>
                              <v-col cols="4">
                                <v-select
                                    @change="refreshWebhookLocation"
                                    v-model="currentEnv.security.mfa.config.webhook.service.name"
                                    label="Service Name"
                                    prepend-icon="mdi-send"
                                    hint="Select which service the webhook should invoke."
                                    :rules="[() => !!currentEnv.security.mfa.config.webhook.service.name || 'This field is required!']"
                                    :items="services"
                                    item-text="name"
                                    item-value="name"
                                ></v-select>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                    type="number"
                                    v-model="currentEnv.security.mfa.config.webhook.service.version"
                                    label="Service Version"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="4">
                                <v-text-field
                                    v-model="currentEnv.security.mfa.config.webhook.service.endpoint"
                                    label="Service Endpoint"
                                ></v-text-field>
                              </v-col>
                            </v-row>
                          </template>

                        </v-expansion-panel-content>
                      </v-expansion-panel>
                      <v-expansion-panel>

                      </v-expansion-panel>
                    </v-expansion-panels>
                  </v-col>
                </v-row>

              </v-card-text>
            </v-card>

          </v-tab-item>
        </v-tabs>
        <v-row>
          <v-col
              cols="12"
              md="12"
              class="text-right"
          >
            <v-btn
                v-if="canAccess({method: 'patch', route: `/environments/:env/registry`})"
                color="success"
                @click="updateRegistry"
            >
              <v-icon class="mr-1">mdi-content-save</v-icon>
              Save
            </v-btn>

          </v-col>
        </v-row>
      </v-col>
    </v-row>

  </v-container>
</template>

<script>

import VJsoneditor from 'v-jsoneditor'
// import globalMixins from "../../../../mixins/globalMixins";
import globalMixins from "@/mixins/globalMixins";
import fieldsMixins from "../environment/components/fieldAccess";

export default {
  name: 'RegistryEdit',

  props: {
    envSelected: {
      type: Object
    }
  },

  components: {
    VJsoneditor
  },

  computed: {
    cors_methods() {
      let c_methods = [];

      if (this.currentEnv.security.cors.methods) {
        if (typeof (this.currentEnv.security.cors.methods) == 'string' && this.currentEnv.security.cors.methods.trim() !== '') {
          c_methods = JSON.parse(JSON.stringify(this.currentEnv.security.cors.methods)).split(',');
        }
      }
      return c_methods;
    },
    cors_icon() {
      if (this.allowed_corsMethods.length === this.cors_methods.length)
        return 'mdi-checkbox-marked-outline';
      return 'mdi-checkbox-blank-outline';
    }
  },

  mixins: [globalMixins, fieldsMixins],

  async created() {
    this.fieldAccess = this.registryUpdateAccess();
    if (this.fieldAccess.security) {
      this.tabs.push(
          {
            label: "Security",
            icon: "shield-half-full"
          }
      );
    }
    setTimeout(() => {
      this.initialize();
    }, 500);
  },

  data: () => ({
    fieldAccess: {},
    tabs: [
      {
        label: "General",
        icon: "cog"
      },
      {
        label: "logger",
        icon: "file-document-edit"
      },
      {
        label: "analytics",
        icon: "chart-bar-stacked"
      }
    ],
    allowed_corsMethods: [],
    currentEnv: null,
    remote: false,
    services: [],
    rules: {
      TTL: [
        v => !!v || 'This field is required!',
        v => (v > 0) || 'The TTL must be greater than 1 minute',
      ],
      URL: [
        v => !!v || 'This field is required!'
      ],
    }

  }),

  methods: {

    async initialize() {
      this.currentEnv = null;
      const registry = await this.getSendData({
        url: `/consoleapi/environments/${this.envSelected.value}/registry`,
        method: "get",
      });

      this.currentEnv = registry.item;
      if (!this.currentEnv.security.mfa) {
        this.currentEnv.security.mfa = {
          config: {}
        };
      }
      if (!this.currentEnv.security.mfa.config) {
        this.currentEnv.security.mfa.config = {};
      }

      this.allowed_corsMethods = JSON.parse(JSON.stringify(this.currentEnv.security.cors.methods)).split(',')
      const services = await this.getSendData({
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/services`,
        method: "get",
        params: {
          fields: ['version', 'name'],
          pagination: false,
        }
      });
      this.services = services.items || [];

    },

    selectAllNoneCorsMethods() {
      this.$nextTick(() => {
        if (this.cors_methods.length !== this.allowed_corsMethods.length) {
          this.allowed_corsMethods = this.cors_methods;
        } else {
          this.allowed_corsMethods = [];
        }
      })
    },

    updateRegistry() {
      const self = this;

      if (this.fieldAccess.security) {
        if (this.currentEnv.security.mfa) {
          if (!Object.hasOwnProperty.call(this.currentEnv.security.mfa, 'enabled')) {
            this.currentEnv.security.mfa.enabled = false;
          }
          if (this.currentEnv.security.mfa.enabled) {

            if (!this.currentEnv.security.mfa.config.redirectURL) {
              self.pushMessage({
                type: 'error',
                title: ``,
                text: `MFA redirectURL is required.`
              });
              return;
            }
            if (!this.currentEnv.security.mfa.config.TTL) {
              self.pushMessage({
                type: 'error',
                title: ``,
                text: `MFA TTL is required.`
              });
              return;
            }

            if (this.currentEnv.security.mfa.config.default) {
              delete this.currentEnv.security.mfa.config.webhook;
            } else {
              // custom // webhook required
              if (this.currentEnv.security.mfa.config.webhook) {
                if (this.currentEnv.security.mfa.config.webhook.uri && this.currentEnv.security.mfa.config.webhook.uri.trim() !== '') {
                  delete this.currentEnv.security.mfa.config.webhook.service;
                } else {
                  if (!this.currentEnv.security.mfa.config.webhook.service.hasOwnProperty('name') ||
                      this.currentEnv.security.mfa.config.webhook.service.hasOwnProperty('name') && this.currentEnv.security.mfa.config.webhook.service.name === '') {
                    delete this.currentEnv.security.mfa.config.webhook.service;
                  }

                }

                if (this.currentEnv.security.mfa.config.webhook.hasOwnProperty('uri') && this.currentEnv.security.mfa.config.webhook.uri.trim() === '') {
                  delete this.currentEnv.security.mfa.config.webhook.uri;
                }

                if (!this.currentEnv.security.mfa.config.webhook.hasOwnProperty('uri') && !this.currentEnv.security.mfa.config.webhook.hasOwnProperty('service')) {
                  delete this.currentEnv.security.mfa.config.webhook;
                }
              }

              if (!this.currentEnv.security.mfa.config.webhook) {
                self.pushMessage({
                  type: 'error',
                  title: ``,
                  text: `Define your custom notification system.`
                });
                return;
              }

            }
          } else {
            delete this.currentEnv.security.mfa.config;
          }
        }
        this.currentEnv.security.cors.methods = this.allowed_corsMethods;
      } else {
        delete this.currentEnv.security;
      }

      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/registry`,
        method: "patch",
        params: {...this.currentEnv}
      };

      self.getSendData(apiOptions)
          .then(() => {
            self.pushMessage({
              type: 'success',
              title: `Registry Updated`,
              text: `The registry of environment ${this.currentEnv.code} has been updated.`
            });
            setTimeout(() => {
              self.initialize();
            }, 2000);
          });
    },

    refreshWebhookLocation() {
      this.currentEnv.security.mfa.config.webhook.service.version = '0';
      this.currentEnv.security.mfa.config.webhook.service.endpoint = '/';
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
  },

  watch: {
    remote: function (to) {
      if (!this.currentEnv.security.mfa.config.webhook) {
        this.currentEnv.security.mfa.config.webhook = {};
      }
      if (to) {
        this.currentEnv.security.mfa.config.webhook.service = {
          name: '',
          version: '0',
          endpoint: '/'
        };
      } else {
        this.currentEnv.security.mfa.config.webhook.uri = '';
      }
    },

    "currentEnv.security.mfa.enabled": function (to) {
      // if MFA enabled:
      // redirect URL required, with a valid full URL or relative URL
      // TTL required min 1
      // if not default
      // => required uri or webhook full
      if (to === false) {
        if (this.currentEnv.security.mfa.config) {
          this.currentEnv.security.mfa.config = {};
        }
      } else if (to === true) {
        if (!this.currentEnv.security.mfa.config) {
          this.currentEnv.security.mfa.config = {};
        }
      }
      this.$nextTick(() => {
        this.$forceUpdate();
      });
    },
    "currentEnv.security.mfa.config.default": function (to) {
      if (to === true) {
        this.currentEnv.security.mfa.config.webhook = {};
      }
      if (to === false) {
        if (!this.currentEnv.security.mfa.config.webhook) {
          this.currentEnv.security.mfa.config.webhook = {
            service: {}
          };
        }
        if (!this.currentEnv.security.mfa.config.webhook.service) {
          this.currentEnv.security.mfa.config.webhook.service = {};
        }
      }
    },

  }
}
</script>

<style scoped>
.routerLink {
  text-decoration: none;
}

.v-card__subtitle {
  font-size: 12px;
}
</style>
