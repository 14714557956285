<template>
  <v-container
      id="extended-tables"
      fluid
      tag="section"
  >
    <v-row no-gutters>
      <v-col cols="12" md="12">
        <data-grid
            :envSelected="envSelected"
            :grid="grid"
            @doAction="gridAction"
        >

          <template v-slot:column-name="{ item }">
            <div class="font-weight-medium">{{ item.name }}</div>
          </template>

          <template v-slot:column-ts="{ item }">
            <div>{{ new Date(item.ts).toDateString() }} @ {{ new Date(item.ts).toLocaleTimeString() }}</div>
          </template>

          <template v-slot:column-expanded="{ headers, item }">
            <td :colspan="headers.length">
              <template v-if="['default','social'].includes(customEntryType)">
                <json-viewer
                    v-if="typeof item.value === 'object' "
                    class="my-4"
                    :value="item.value"
                    :expand-depth="4"
                    theme="my-awesome-json-theme"
                    copyable
                    boxed
                    sort
                ></json-viewer>
                <v-card
                    v-else
                    class="my-4"
                    elevation="1"
                >
                  <v-card-text>
                    <div class="text-right">
                      <a class="copy-text-btn" @click="copyTextToClipboard(`custom-entries-txt-${item.id}`)">copy</a>
                    </div>
                    <div class="my-awesome-json-theme" :id="'custom-entries-txt-' + item.id">{{ item.value }}</div>
                  </v-card-text>
                </v-card>
              </template>
              <template v-else-if="customEntryType ==='arw'">
                <v-row>
                  <v-col
                      v-for="(stageInfo, stageName) in item.value"
                      :key="stageName"
                      cols="2"
                      lg="4"
                      xl="2"
                      md="4"
                      sm="2"
                      xs="1"
                      class="d-flex"
                      style="flex-direction:column"
                  >
                    <v-card class="mb-1 flex-grow-1 card-outter" height="100%">
                      <v-card-title class="text-capitalize">{{ stageName }}</v-card-title>
                      <v-divider></v-divider>
                      <v-card-text>
                        <v-list flat>
                          <v-list-item v-for="(propInfo, prop) in stageInfo" :key="prop">
                            <v-list-item-content>
                              <v-list-item-title class="text-capitalize">
                                {{ prop }}
                              </v-list-item-title>
                            </v-list-item-content>
                            <v-list-item-action>
                              <v-btn icon x-small @click="openInfo(prop, propInfo)">
                                <v-icon color="success lighten-1">mdi-information</v-icon>
                              </v-btn>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>

                      </v-card-text>
                      <v-card-actions class="card-actions">
                        <v-spacer></v-spacer>
                        <v-btn v-if="canAccess({route: '/environments/:env/custom-entries/:id', method: 'patch'})"
                               x-small color="success"
                               class="mr-1"
                               @click="editItem(item, stageName)"
                        >
                          <v-icon class="mr-1" x-small>mdi-pencil</v-icon>
                          Edit
                        </v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </template>
            </td>

          </template>
        </data-grid>

      </v-col>
    </v-row>

    <clone-dialog
        :envSelected="envSelected"
        :options="cloneDialog"
        :doAction="doClone"
    ></clone-dialog>

    <share-dialog
        :envSelected="envSelected"
        :options="shareDialog"
        :doAction="doShare"
    >
    </share-dialog>

    <custom-dialog :dialog="propInfoDialog" :env-selected="envSelected">
      <json-viewer
          class="my-4"
          :value="propInfoDialog.data"
          :expand-depth="4"
          theme="my-awesome-json-theme"
          boxed
          expanded
          sort
      ></json-viewer>
    </custom-dialog>
  </v-container>
</template>

<script>

import globalMixins from "../../../../mixins/globalMixins";
import dataGrid from "@/views/internal/components/custom/dataGrid";
import CloneDialog from "@/views/internal/components/custom/cloneDialog"
import ShareDialog from "@/views/internal/components/custom/shareDialog"
import CustomDialog from "@/views/internal/components/custom/dialog"
import JsonViewer from 'vue-json-viewer';

export default {
  name: 'CustomEntries',
  props: {
    environments: {
      type: Array
    },
    envSelected: {
      type: Object
    },
    customEntryType: {
      type: String
    }
  },

  data: () => {
    return {
      grid: {
        //mandatory
        headers: [
          // {
          //   text: '',
          //   value: 'data-table-expand'
          // },
          {
            text: 'Name',
            value: 'name',
          },
          {
            text: 'Last Modified',
            value: 'ts',
          },
          {
            align: 'right',
            text: 'Actions',
            value: 'actions',
          },
        ],
        items: [],
        selected: [],
        //optional
        loading: true,
        //optional
        columns: [
          {
            name: 'item.name'
          },
          {
            name: 'item.ts'
          },
          {
            name: 'item.expanded'
          }
        ],
        //optional
        search: {
          keyword: ''
        },
        //optional
        itemID: 'name',
        //optional
        expand: {
          single: false,
          items: []
        },
        //optional
        multi: [
          {
            icon: 'delete',
            label: 'Delete',
            color: 'error',
            acl: {method: 'delete', route: `/environments/:env/custom-entries/:id`},
            method: 'deleteItem',
            //optional
            confirm: `Are you sure you want to delete these Custom Setting(s)?`
          }
        ],
        //optional
        sortingList: ['name', 'ts'],
        //optional
        headActions: [
          {
            icon: 'refresh',
            label: '',
            color: 'purple',
            acl: {method: 'get', route: `/environments/:env/:type/custom-entries`},
            method: 'listItems'
          },
          {
            icon: 'plus',
            label: 'create',
            color: 'success',
            acl: {method: 'put', route: `/environments/:env/custom-entries`},
            method: 'addItem'
          }
        ],
        //optional
        rowActions: [
          {
            method: 'lockUnlockItem',
            color: 'error',
            icon: 'mdi-lock',
            alternate: {
              key: 'locked',
              color: 'success',
              icon: 'mdi-lock-open-variant'
            },
            allow: {
              method: 'patch',
              route: '/environments/:env/custom-entries/:id'
            },
            tooltip: 'Lock / Unlock'
          },
          {
            method: 'cloneItem',
            color: 'secondary',
            icon: 'mdi-content-copy',
            allow: {
              method: 'post',
              route: '/environments/:env/custom-entries/:id/copy'
            },
            tooltip: 'Clone'
          },
          {
            method: 'shareItem',
            color: 'primary',
            icon: 'mdi-share-variant',
            allow: {
              method: 'post',
              route: '/environments/:env/custom-entries/:id/share'
            },
            tooltip: 'Share'
          },
          {
            method: 'editItem',
            color: 'success',
            icon: 'mdi-pencil',
            allow: {
              method: 'patch',
              route: '/environments/:env/custom-entries/:id'
            },
            tooltip: "Edit"
          },
          {
            method: 'deleteItem',
            color: 'error',
            icon: 'mdi-delete',
            allow: {
              method: 'delete',
              route: '/environments/:env/custom-entries/:id'
            },
            tooltip: "Delete"
            // optional
            // confirm: `Are you sure you want to delete this Custom Setting?`
          },
        ]
      },
      cloneDialog: {
        trigger: false,
        original: {},
        actions: [
          {
            color: 'success',
            icon: 'content-copy',
            label: 'Clone',
            method: 'doClone',
            allow: {
              method: 'post',
              route: '/environments/:env/custom-entries/:id/copy'
            }
          }
        ]
      },
      shareDialog: {
        trigger: false,
        original: {},
        list: [],
        actions: [
          {
            color: 'success',
            icon: 'share-variant',
            label: 'Share',
            method: 'doShare',
            allow: {
              method: 'post',
              route: '/environments/:env/custom-entries/:id/share'
            }
          }
        ]
      },
      propInfoDialog: {
        title: '',
        trigger: false,
        data: null
      }
    };
  },

  components: {
    dataGrid,
    JsonViewer,
    CloneDialog,
    ShareDialog,
    CustomDialog
  },

  mixins: [globalMixins],

  async created() {
    if (this.customEntryType === 'arw' || this.customEntryType === 'social') {
      this.grid.rowActions.shift();
    }
    this.listItems();
  },

  methods: {

    //list
    async listItems() {
      this.grid.loading = true;
      this.grid.items = [];
      const apiOptions = {
        noLoading: true,
        url: `/consoleapi/environments/${this.envSelected.value}/${this.customEntryType}/custom-entries`,
        method: "get",
      };

      this.getSendData(apiOptions).then((response) => {
        this.grid.items = response.items || [];
        this.grid.loading = false;
      });
    },

    //grid action hook
    gridAction(action, item, extra) {
      if (item) {
        this[action](item, extra);
      } else {
        this[action](extra);
      }
    },

    //add
    addItem() {
      this.goToPage({
        route: 'createCustomRegistry',
        params: {type: this.customEntryType}
      });
    },

    //edit
    editItem(item, stageName) {
      this.goToPage({
        route: 'editCustomRegistry',
        params: {
          id: item.id,
          type: this.customEntryType, stage: stageName
        }
      });
    },

    //lock | unlock
    lockUnlockItem: function (item) {
      const self = this;
      let op = !item.locked ? 'LOCKED' : 'Unlocked';
      item.locked = !item.locked;

      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/custom-entries/${item.id}`,
        method: "patch",
        params: {
          name: item.name,
          locked: item.locked,
          type: item.type,
          value: item.value,
        }
      };

      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Item ${op}`,
          text: `This custom entry has been ${op}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 4000);
      });
    },

    //delete
    deleteItem(item, multi) {
      const _self = this;
      if (multi || (!multi && confirm(`Are you sure you want to remove custom entry: ${item.name}`))) {
        doDelete();
      }

      function doDelete() {
        const apiOptions = {
          url: `/consoleapi/environments/${_self.envSelected.value}/custom-entries/${item.id}`,
          method: "delete"
        };
        _self.getSendData(apiOptions).then((response) => {
          _self.pushMessage({
            type: 'success',
            title: `Item Deleted`,
            text: `This custom entry has been deleted.`
          });
          setTimeout(() => {
            _self.listItems();
          }, 2000);
        });
      }
    },

    //clone
    cloneItem(item) {
      this.cloneDialog.original = {...item};
      this.cloneDialog.trigger = true;
    },

    doClone(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/custom-entries/${item.id}/copy`,
        method: "post",
        params: {name: value}
      };
      this.cloneDialog.trigger = false;
      self.getSendData(apiOptions).then((response) => {
        self.pushMessage({
          type: 'success',
          title: `Item Copied`,
          text: `Custom entry has been copied to ${value}.`
        });
        setTimeout(() => {
          self.listItems();
        }, 2000);
      });
    },

    //share
    shareItem(item) {
      this.shareDialog.original = {...item};
      this.shareDialog.trigger = true;
      this.shareDialog.list = [...this.environments];
      for (let i = this.shareDialog.list.length - 1; i >= 0; i--) {
        if (item.env[this.shareDialog.list[i].code.toUpperCase()]) {
          this.shareDialog.list.splice(i, 1);
        }
      }
    },

    doShare(value, item) {
      const self = this;
      const apiOptions = {
        url: `/consoleapi/environments/${self.envSelected.value}/custom-entries/${item.id}/share`,
        method: "post",
        params: {environments: value}
      };
      this.shareDialog.trigger = false;
      self.getSendData(apiOptions)
          .then((response) => {
            self.pushMessage({
              type: 'success',
              title: `Item Shared`,
              text: `Custom entry has been shared with environments: ${value.join(' - ')}.`
            });
            setTimeout(() => {
              self.listItems();
            }, 2000);
          }).catch(error => {
        if (error.errors['2101']) {
          self.pushMessage({
            type: 'error',
            title: `Missing Tenant`,
            text: `Make sure all the tenants used in this entry are also shared to the required environments`
          });
        }
      });
    },

    //info box
    openInfo(propName, propInfo) {
      this.propInfoDialog.title = propName;
      this.propInfoDialog.data = {...propInfo};
      this.propInfoDialog.trigger = true;
    }
  }
}
</script>

<style scoped lang="scss">
.routerLink {
  text-decoration: none;
}

.card-outter {
  position: relative;
  padding-bottom: 30px;
}

.card-actions {
  border-top: solid 1px #eee;
  width: 100%;
  position: absolute;
  bottom: 0;
}

</style>
